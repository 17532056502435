.parent-station {
    display: flex;
    flex-direction: column;
    height: 100%;

    canvas.audio-visualiser {
        width: 100%;
        height: 30vh;
        margin-top: 20vh;
    }

    .video-container {
        display: none;
        position: relative;

        video {
            flex-grow: 1;
            width: 100%;
            height: 100%;
            max-height: calc(100vh - 250px);
            object-fit: contain;
        }

        .btn-play-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .video-controls {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);

            .volume-slider {
                width: 100px;
            }
        }
    }

    &.audio-and-video {
        .video-container {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            min-width: 350px;
            background-color: #000000;
        }
    }
}